<template>
  <div>
    <form-create
        :is-add-new-sidebar-active.sync="isAddNewSidebarActive"
        :lista-usuarios="listaUsuarios"
        titulo="Nova Obra"
        @refetch-data="refetchData"
    />
    <!-- Table Container Card -->
    <b-card
        no-body
        class="mb-0"
    >
      <h5 class="card-title pt-2 pl-2 pb-0 mb-0">
        Lista de Obras
      </h5>
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Exibição</label>
            <v-select
                v-model="perPage"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="perPageOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
            />

          </b-col>

          <!-- Search -->
          <b-col
              cols="12"
              md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                  v-model="searchQuery"
                  class="d-inline-block mr-1"
                  placeholder="Pesquisar..."
              />
              <b-button
                  variant="success"
                  @click="isAddNewSidebarActive = true"
              >
                <span class="text-nowrap">
                  Adicionar
                </span>
              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
          ref="refUserListTable"
          class="position-relative font-size-9"
          :items="fetchDados"
          responsive
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="sortBy"
          show-empty
          empty-text="Nenhum resultado encontrado"
          :sort-desc.sync="isSortDirDesc"
          :busy="isLoad"
          striped
      >
        <template #table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle"/>
            <strong> Carregando...</strong>
          </div>
        </template>
        <template #cell(cliente.razao_social)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                  size="4.5em"
                  :src="data.item.cliente.imagem"
                  :text="avatarText(data.item.cliente.razao_social)"
                  :variant="'secondary'"
                  class="avatar-with-shadow"
              />
            </template>
            <div><small>
              <TooltipTextoCortado
                  :texto="data.item.cliente.razao_social"
                  :limite="25"
              />
            </small></div>
          </b-media>
        </template>
        <!-- Column: Nome e Imagem -->
        <template #cell(nome)="data">
          <b-media vertical-align="center">
            <template #aside>

              <b-avatar
                  size="4.5em"
                  :src="data.item.imagem"
                  :text="avatarText(data.item.nome)"
                  :variant="'secondary'"
                  class="avatar-with-shadow"
              />
            </template>
            <div>{{ data.item.nome }} <br> <small>Membros: {{ data.item.users.length }}</small></div>
          </b-media>
        </template>

        <template #cell(users)="data">
          <popover-integrantes
              :itens="data.item.users"
              :prefix="data.item.uuid"
              :badget-group="{
              text: 'M',
              variant: 'primary'
            }"
              :popover-disabled="true"
          />

        </template>
        <template #cell(registros_count)="data">

          <feather-icon
              v-if="data.item.registros_em_analise_count > 0"
              v-b-tooltip:top="'Obra com registros em análise.'"
              icon="AlertTriangleIcon"
              size="16"
              class="text-warning mr-1"
          />
          <span v-if="data.item.registros_em_analise_count > 0"> {{
              data.item.registros_em_analise_count
            }}/</span>{{ data.item.registros_count }}

        </template>
        <!-- Column: Actions -->
        <template #cell(ações)="data">
          <b-dropdown
              variant="link"
              no-caret
              :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
              />
            </template>

            <b-dropdown-item :to="{ name: 'obras-edit', params: { id: data.item.uuid } }">

              <feather-icon icon="EditIcon"/>
              <span class="align-middle ml-50">Gerenciar</span>
            </b-dropdown-item>
            <b-dropdown-item
                v-if="data.item.registros_count > 0"
                :to="{ name: 'obras-registros', params: { id: data.item.uuid } }"
            >

              <feather-icon icon="SmartphoneIcon"/>
              <span class="align-middle ml-50">Registros</span>
            </b-dropdown-item>

            <b-dropdown-item @click="modalExcluir(data.item)">
              <feather-icon icon="TrashIcon"/>
              <span class="align-middle ml-50">Excluir</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Exibindo {{ dataMeta.from }} a {{ dataMeta.to }} dos {{ dataMeta.of }} resultados
              encontrados</span>
          </b-col>
          <!-- Pagination -->
          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
                v-model="currentPage"
                :total-rows="totalItens"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
      <modalConfirmacao
          id="modal-confirmacao"
          titulo="Excluir Item"
          :item-id="dadosItem.id"
          :item-nome="dadosItem.nome"
          @itemConfirmado="excluirItem"
          @click="modalExcluir(data.item)"
      />
    </b-card>
  </div>
</template>

<script>
import { avatarText } from '@core/utils/filter'
import {
  BAvatar,
  BButton,
  BCard,
  BCol,
  BDropdown,
  BDropdownItem,
  BFormInput,
  BMedia,
  BPagination,
  BRow,
  BSpinner,
  BTable,
  VBTooltip,
} from 'bootstrap-vue'
import { onUnmounted, ref } from '@vue/composition-api'
import modalConfirmacao from '@/views/components/componentesPensou/ModalConfirmacao.vue'
import obraStoreModule from '../obraStoreModule'
import PopoverIntegrantes from '@/views/components/componentesPensou/PopoverIntegrantes.vue'
import store from '@/store'
import TooltipTextoCortado from '@/views/components/componentesPensou/TooltipTextoCortado.vue'
import useItemList from './useItemList'
import vSelect from 'vue-select'
import FormCreate from './FormCreate.vue'

export default {
  components: {
    TooltipTextoCortado,
    PopoverIntegrantes,
    FormCreate,
    modalConfirmacao,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BDropdown,
    BDropdownItem,
    BPagination,
    BSpinner,
    vSelect,

  },
  directives: {
    'b-tooltip': VBTooltip,
  },

  setup() {
    const OBRAS_APP_STORE_MODULE_NAME = 'app-obras'

    // Register module
    if (!store.hasModule(OBRAS_APP_STORE_MODULE_NAME)) store.registerModule(OBRAS_APP_STORE_MODULE_NAME, obraStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(OBRAS_APP_STORE_MODULE_NAME)) store.unregisterModule(OBRAS_APP_STORE_MODULE_NAME)
    })

    const isAddNewSidebarActive = ref(false)
    const listaUsuarios = ref([])

    const {
      fetchDados,
      fetchUsuarios,
      tableColumns,
      perPage,
      currentPage,
      totalItens,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,
      deleteItem,
    } = useItemList()

    store.dispatch('app-obras/fetchUsuarios')
        .then(response => {
          listaUsuarios.value = response.data
        })

    return {

      // Sidebar
      isAddNewSidebarActive,
      deleteItem,
      fetchDados,
      listaUsuarios,
      tableColumns,
      perPage,
      currentPage,
      totalItens,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,
      fetchUsuarios,
      avatarText,

    }
  },
  data() {
    return {
      dadosItem: {
        id: '',
        nome: '',
      },
    }
  },
  computed: {
    isLoad() {
      return this.$store.state['app-obras'].load
    },
  },

  methods: {
    modalExcluir(item) {
      this.dadosItem.nome = item.titulo
      this.dadosItem.id = item.uuid
      this.$root.$emit('bv::show::modal', 'modal-confirmacao')
    },
    excluirItem(dados) {
      this.deleteItem(dados)
    },
  },

}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-select.scss';

.per-page-selector {
  width: 90px;
}
</style>
