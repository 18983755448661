<template>
  <b-sidebar
    id="add-new-sidebar"
    :visible="isAddNewSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{ titulo }}
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <validation-provider
            #default="validationContext"
            name="Nome da Obra"
            rules="required"
          >
            <b-form-group
              label="Nome da Obra"
              label-for="nome-obra"
            >
              <b-form-input
                id="nome-time"
                v-model="itemData.titulo"
                name="nome_time"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="Informe o nome da obra"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <validation-provider
            #default="validationContext"
            name="Data Início Obra"
            rules="required"
          >
            <b-form-group
              label="Data Início Obra"
              label-for="inicio-obra"
            >
              <b-form-input
                id="inicio-obra"
                v-model="itemData.data_inicio"
                type="date"
                name="inicio-obra"
                :state="getValidationState(validationContext)"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <validation-provider
            #default="validationContext"
            name="Cliente"
            rules="required"
          >
            <b-form-group
              label="Cliente"
              label-for="cliente"
            >
              <select-com-pesquisa
                placeholder="Escolha um cliente"
                :itens-para-edicao="[]"
                url="/clientes"
                @selecionados="tratarEventoClienteSelecionados"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <validation-provider
            #default="validationContext"
            name="Cliente"
            rules="required"
          >
            <b-form-group
              label="Assessoria"
              label-for="assessoria"
            >
              <select-com-pesquisa
                placeholder="Escolha uma empresa"
                :itens-para-edicao="[]"
                url="/empresas"
                @selecionados="tratarEventoAssessoriaSelecionada"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <validation-provider
            #default="validationContext"
            name="logradouro"
            rules="required"
          >
            <b-form-group
              label="Logradouro"
              label-for="logradouro"
            >
              <b-form-input
                id="logradouro-obra"
                v-model="itemData.endereco.logradouro"
                name="logradouro"
                :state="getValidationState(validationContext)"
                trim
                placeholder="Ex: Rua, Avenida, etc."
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <validation-provider
            #default="validationContext"
            name="numero"
            rules="required"
          >
            <b-form-group
              label="Número"
              label-for="número"
            >
              <b-form-input
                id="numero"
                v-model="itemData.endereco.num_endereco"
                name="número"
                :state="getValidationState(validationContext)"
                trim
                placeholder="Ex: 123, 123A, etc."
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <validation-provider
            #default="validationContext"
            name="Complemento"
          >
            <b-form-group
              label="Complemento (Opcional)"
              label-for="Complemento"
            >
              <b-form-input
                id="contact"
                v-model="itemData.endereco.complemento"
                :state="getValidationState(validationContext)"
                trim
                placeholder="Ex: Apto 500"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <validation-provider
            #default="validationContext"
            name="bairro"
            rules="required"
          >
            <b-form-group
              label="Bairro"
              label-for="bairro"
            >
              <b-form-input
                id="bairro"
                v-model="itemData.endereco.bairro"
                name="bairro-obra"
                :state="getValidationState(validationContext)"
                trim
                placeholder="Ex: Centro, Jardim, etc."
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            name="estado"
            rules="required"
          >
            <b-form-group
              label="Estado"
              label-for="uf-obra"
            >
              <b-form-input
                id="uf"
                v-model="itemData.endereco.estado"

                name="uf-obra"
                :maxlength="2"
                pattern="[A-Za-z]{2}"
                :state="getValidationState(validationContext)"
                trim
                placeholder="Ex: SC, SP, etc."
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <validation-provider
            #default="validationContext"
            name="cidade"
            rules="required"
          >
            <b-form-group
              label="Cidade"
              label-for="cidade-obra"
            >
              <b-form-input
                id="cidade"
                v-model="itemData.endereco.cidade"
                name="cidade-obra"
                :state="getValidationState(validationContext)"
                trim
                placeholder="Ex: São Paulo, Rio de Janeiro, etc."
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <validation-provider
            #default="validationContext"
            name="cep"
            rules="required"
          >
            <b-form-group
              label="CEP"
              label-for="cep-obra"
            >
              <b-form-input
                id="cep"
                v-model="itemData.endereco.cep"
                v-mask="'##.###-####'"
                name="cep-obra"
                :state="getValidationState(validationContext)"
                trim
                placeholder="Ex: 95.650-000"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="success"
              class="mr-2"
              type="submit"
              @click="onSubmit()"
            >
              Salvar
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancelar
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BButton, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BSidebar,
} from 'bootstrap-vue'
import { ref } from '@vue/composition-api'
import { required } from '@validations'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'

import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import SelectComPesquisa from '@/views/components/componentesPensou/SelectComPesquisa.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    SelectComPesquisa,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewSidebarActive',
    event: 'update:is-add-new-sidebar-active',
  },
  props: {
    isAddNewSidebarActive: {
      type: Boolean,
      required: true,
    },
    titulo: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      required,
      selectedFile: null,
      previewUrl: null,

    }
  },
  setup(props, { emit }) {
    const blankUserData = {
      nome: '',
      cliente_id: '',
      endereco: {
        logradouro: '',
        numero: '',
        bairro: '',
        complemento: '',
        cidade: '',
        uf: '',
        cep: '',
      },

    }
    const isAddNewSidebarActive = ref(false)
    // Use toast
    const toast = useToast()

    const itemData = ref(JSON.parse(JSON.stringify(blankUserData)))
    const resetItemData = () => {
      itemData.value = JSON.parse(JSON.stringify(blankUserData))
    }

    const onSubmit = () => {
      store.dispatch('app-obras/store', itemData.value)
        .then(respo => {
          emit('refetch-data')
          emit('update:is-add-new-sidebar-active', false)
          toast({
            component: ToastificationContent,
            props: {
              title: 'Obra adicionada com sucesso',
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          })
        })
        .catch(error => {
          if (error.response && error.response.status === 422) {
            const listaErros = error.response.data.errors
            for (const field in listaErros) {
              if (listaErros.hasOwnProperty(field)) {
                const errorMessages = listaErros[field]
                errorMessages.forEach(errorMessage => {
                  toast({
                    component: ToastificationContent,
                    props: {
                      title: errorMessage,
                      icon: 'AlertCircleIcon',
                      variant: 'danger',
                    },
                  })
                })
              }
            }
          }
        })
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetItemData)

    return {
      itemData,
      onSubmit,

      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
  methods: {
    tratarEventoClienteSelecionados(item) {
      this.itemData.cliente_id = item.id
    },
    tratarEventoAssessoriaSelecionada(item) {
      this.itemData.assessoria = item.id
    },
  },

}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
